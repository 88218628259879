import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import HoverButton from './HoverButton'
import Edit from './Edit'
import { stringComparator, withValue, withoutValue, withoutValues } from '../../js-lib/array-helpers'
import { filter } from '../recipe-filters'
import Icon from '../../app/icon'
import RecipeSelector from '../selector'
import AuthSelector from '../../app/AuthSelector'
import { acceptInvite, declineInvite } from '../../shoppingList/api'
import SharedRecipeSelector from '../sharedRecipeSelector'
import { reissue } from '../../app/api'
import { TagAutoComplete, withNavigation } from '../../js-lib-react/'

const IMAGE_API = process.env.IMAGE_API_URL || 'https://images.palatable.kitchen'

class RecipeList extends React.Component {
  constructor(props) {
    super(props)
    let recipeFilters = localStorage.getItem('palatable.recipeFilters')
    recipeFilters = recipeFilters ? JSON.parse(recipeFilters) : { includes: [], excludes: [] }

    this.state = {
      showAddForm: false,
      filterIncludes: recipeFilters.includes,
      filterExcludes: recipeFilters.excludes,
    }
  }

  addFilter(me, filterTag) {
    me.setState({ filterIncludes: withValue(me.state.filterIncludes, filterTag) })
    me.persistFilters()
  }

  switchFilter(me, filterTag) {
    me.setState({
      filterIncludes: withoutValue(me.state.filterIncludes, filterTag),
      filterExcludes: withValue(me.state.filterExcludes, filterTag),
    })
    me.persistFilters()
  }

  removeFilter(me, filterTag) {
    me.setState({
      filterIncludes: withoutValue(me.state.filterIncludes, filterTag),
      filterExcludes: withoutValue(me.state.filterExcludes, filterTag),
    })
    me.persistFilters()
  }

  persistFilters() {
    localStorage.setItem(
      'palatable.recipeFilters',
      JSON.stringify({
        includes: this.state.filterIncludes,
        excludes: this.state.filterExcludes,
      })
    )
  }

  render() {
    const { props, state } = this
    const mineOnly = state.filterIncludes.indexOf('Mine') > -1
    const notMine = state.filterExcludes.indexOf('Mine') > -1
    let recipesToShow = notMine ? [] : props.recipes
    if (!mineOnly) {
      recipesToShow = recipesToShow.concat(props.sharedRecipes)
    }
    recipesToShow = recipesToShow.sort((a, b) => stringComparator(a.name, b.name))

    const recipeSources = []
    recipesToShow.forEach((r) => {
      if (r.source) {
        let source = r.source.trim()
        if (source.startsWith('http')) {
          source = new URL(r.source).hostname
        }
        if (recipeSources.indexOf(source) === -1) {
          recipeSources.push(source)
        }
      }
    })

    if (state.filterIncludes.length > 0 || state.filterExcludes.length > 0) {
      recipesToShow = filter(
        recipesToShow,
        withoutValue(this.state.filterIncludes, 'Mine'),
        withoutValue(this.state.filterExcludes, 'Mine')
      )
    } else {
      if (!props.settings.view) return null
      if (props.settings.view === 'recentlyViewed') recipesToShow = props.recentRecipes
      if (props.settings.view === 'recentlyUpdated') recipesToShow = recipesToShow.sort((a, b) => b.lastUpdated - a.lastUpdated)
      // if (props.settings.view === 'alphabetically') // already sorted above
      if (props.settings.view === 'withTag')
        recipesToShow = recipesToShow.filter((r) => (r.tags || []).indexOf(props.settings.viewTag) > -1)
    }

    const filters = [...state.filterIncludes, ...state.filterExcludes]

    const meals = ['Breakfast', 'Brunch', 'Lunch', 'Dinner', 'Snack']
    const cuisines = [
      'Asian',
      'Austrian',
      'Colombian',
      'Czech',
      'Filipino',
      'Georgian',
      'German',
      'Hungarian',
      'Indonesian',
      'Italian',
      'Malaysian',
      'Mexican',
      'Polish',
      'Singaporean',
      'Venezuelan',
    ]

    return (
      <div>
        <div>
          <Link className="float-right button" to="/recipeSettings">
            <Icon width="16" height="16" id="settings" />
          </Link>
        </div>
        <h1 className="subtitle">Recipes</h1>

        {props.recipes.length > 3 && !props.isSignedUp && (
          <div style={{ marginBottom: '10px', padding: '10px', borderRadius: '5px', backgroundColor: '#e5795c', color: 'white' }}>
            <p>
              Your recipes are being stored locally in your browser and may be deleted whenever the browser deems necessary. Please{' '}
              <Link style={{ color: 'black' }} to="/signup">
                sign up
              </Link>{' '}
              to keep your recipes safe.
            </p>
          </div>
        )}

        {props.invites && props.invites.length > 0 && (
          <div style={{ marginBottom: '10px', padding: '10px', borderRadius: '5px', backgroundColor: '#e5795c', color: 'white' }}>
            {props.invites.map((invite) => {
              return (
                <div key={invite.id}>
                  <p>{invite.inviterUsername} has invited you to see their recipes. Would you like to see them?</p>
                  <button
                    className="button"
                    onClick={async (e) => {
                      await acceptInvite(invite.id)
                      window.storageService.sync('invite')
                      props.authTokenFetched(await reissue())
                      props.loadSharedRecipes()
                    }}
                  >
                    Yes, see recipes
                  </button>{' '}
                  <button
                    className="button"
                    onClick={(e) => {
                      declineInvite(invite.id).then((updated) => {
                        window.storageService.sync('invite')
                      })
                    }}
                  >
                    No
                  </button>
                </div>
              )
            })}
          </div>
        )}

        {!state.showAddForm && (
          <div style={{ display: 'flex' }}>
            <div style={{ flexGrow: '1' }}>
              <div style={{ marginBottom: '10px' }}>
                <TagAutoComplete
                  placeholder="Search recipes"
                  allTags={['Mine', ...props.allTags]}
                  exclusions={filters}
                  onSelect={(text) => this.addFilter(this, text)}
                />
              </div>
              <div style={{ marginBottom: '15px' }}>
                {filters.sort(stringComparator).map((tag) => {
                  return (
                    <button
                      className={'button marginRight' + (this.state.filterExcludes.indexOf(tag) > -1 ? ' is-danger' : '')}
                      onClick={(e) => {
                        e.preventDefault()
                        if (this.state.filterIncludes.indexOf(tag) > -1) {
                          this.switchFilter(this, tag)
                        } else {
                          this.removeFilter(this, tag)
                        }
                      }}
                    >
                      {tag}
                    </button>
                  )
                })}
                {filters.length > 0 && (
                  <button
                    className="button"
                    style={{ marginLefta: '10px' }}
                    onClick={(e) => {
                      state.filterIncludes.forEach((tag) => this.removeFilter(this, tag))
                      state.filterExcludes.forEach((tag) => this.removeFilter(this, tag))
                    }}
                  >
                    <Icon width="16" height="16" id="delete" />
                  </button>
                )}
              </div>
            </div>
            <button
              className="button"
              style={{ marginLeft: '10px' }}
              onClick={(e) => {
                this.setState({ showComplexFilter: !state.showComplexFilter })
              }}
            >
              <Icon width="16" height="16" id="categorise" />
            </button>
          </div>
        )}

        {state.showComplexFilter && (
          <div>
            Meal:
            <ul style={{ marginBottom: '10px' }}>
              {meals.map((meal) => {
                return (
                  <li style="list-style-type: disc; marginLeft: 25px;">
                    <a
                      href=""
                      onClick={(e) =>
                        e.preventDefault() || this.addFilter(this, 'tag: ' + meal) || this.setState({ showComplexFilter: false })
                      }
                    >
                      {meal}
                    </a>
                  </li>
                )
              })}
            </ul>
            Cuisine:
            <ul style={{ marginBottom: '10px' }}>
              {cuisines.map((cuisine) => {
                return (
                  <li style="list-style-type: disc; marginLeft: 25px;">
                    <a
                      href=""
                      onClick={(e) =>
                        e.preventDefault() || this.addFilter(this, 'tag: ' + cuisine) || this.setState({ showComplexFilter: false })
                      }
                    >
                      {cuisine}
                    </a>
                  </li>
                )
              })}
            </ul>
            {props.allTags.length > 0 && (
              <div>
                Tag:
                <ul style={{ marginBottom: '10px' }}>
                  {withoutValues(withoutValues(props.allTags, cuisines), meals)
                    .sort()
                    .map((tag) => {
                      return (
                        <li style="list-style-type: disc; marginLeft: 25px;">
                          <a
                            href=""
                            onClick={(e) =>
                              e.preventDefault() || this.addFilter(this, 'tag: ' + tag) || this.setState({ showComplexFilter: false })
                            }
                          >
                            {tag}
                          </a>
                        </li>
                      )
                    })}
                </ul>
              </div>
            )}
            {props.username === 'carwinz@gmail.com' && (
              <div>
                Source:
                <ul style={{ marginBottom: '10px' }}>
                  {recipeSources
                    .sort((a, b) => stringComparator(a, b))
                    .map((recipeSource) => {
                      return (
                        <li style="list-style-type: disc; marginLeft: 25px;">
                          <a
                            href=""
                            onClick={(e) =>
                              e.preventDefault() ||
                              this.addFilter(this, 'source: ' + recipeSource) ||
                              this.setState({ showComplexFilter: false })
                            }
                          >
                            {recipeSource}
                          </a>
                        </li>
                      )
                    })}
                </ul>
              </div>
            )}
          </div>
        )}

        {!state.showAddForm && (
          <div>
            {recipesToShow.length === 0 && <p>No recipes found</p>}

            <div>
              {recipesToShow.map((recipe) => {
                return (
                  <div key={recipe.id} className="list-item">
                    <Link to={'/recipe/' + recipe.id}>
                      <div className="columns is-mobile">
                        <div className="column is-narrow" width="100px" style={{ minWidth: '124px' }}>
                          {recipe.images && recipe.images.length > 0 && (
                            <img
                              style={{ paddingLeft: '-10px' }}
                              width="100px"
                              src={IMAGE_API + '/100x100/' + recipe.images[0].id + '.jpg'}
                              alt={recipe.name}
                            />
                          )}
                        </div>
                        <div className="column">
                          <p className="emphasised">{recipe.name}</p>
                          <p className="less-important">{recipe.intro}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                )
              })}
            </div>

            <div className="hover-button-wrapper">
              <HoverButton title="Add Recipe" iconId="add" onClick={(e) => this.setState({ showAddForm: true })} />
            </div>
          </div>
        )}

        {state.showAddForm && (
          <Edit
            recipe={{ tags: [] }}
            allTags={this.props.allTags}
            onFinished={(recipe) => {
              if (recipe) {
                this.props.navigate('/recipe/' + recipe.id)
              } else {
                this.setState({ showAddForm: false })
              }
            }}
          />
        )}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    authTokenFetched: (token) => {
      dispatch({ type: 'AUTHENTICATED', token })
    },
    viewsFetched: (views) => {
      dispatch({ type: 'RECENT_RECIPES_FETCHED', payload: views })
    },
    loadSharedRecipes: () => {
      dispatch({ type: 'LOAD_SHARED_RECIPES' })
    },
  }
}

export default withNavigation(
  connect((state, ownProps) => {
    const authSelector = new AuthSelector(state)
    const sharedRecipeSelector = new SharedRecipeSelector(state)
    const username = authSelector.username()
    const recipes = Object.values(state.recipe.byId).sort((a, b) => stringComparator(a.name, b.name))
    let recentRecipes = Object.values(state.recipeView.byId)
      .sort((a, b) => b.viewTime - a.viewTime)
      .map((rv) => {
        let result = state.recipe.byId[rv.id]
        if (!result) {
          result = sharedRecipeSelector.get(rv.id)
        }
        return result
      })
      .filter((i) => i)

    let view = state.setting.byName['recipe.listingPageContent']
    const viewTag = state.setting.byName['recipe.listingPageContentTag']
    if (view === 'withTag' && !viewTag) {
      view = 'alphabetically'
    }

    return {
      recipes,
      sharedRecipes: sharedRecipeSelector.list(),
      recentRecipes: [...new Set(recentRecipes)],
      invites: Object.values(state.invite.byId).filter((i) => i.type === 'recipe' && i.archived !== true && i.inviteeUsername === username),
      allTags: new RecipeSelector(state).allTags(),
      settings: { view, viewTag },
      isSignedUp: !!authSelector.token(),
      username: authSelector.username(),
    }
  }, mapDispatchToProps)(RecipeList)
)
